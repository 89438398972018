import { useState } from "react";
const initialDiagramState = { svg: "", d2: "" };
const placeholder = `
---
# Source: example/templates/services.yaml
apiVersion: v1
kind: Service
metadata:
  name: pawn
spec:
  selector:
    app.kubernetes.io/name: pawn
  ports:
    - protocol: TCP
      port: 80
      targetPort: 9376
---
# Source: example/templates/services.yaml
apiVersion: v1
kind: Service
metadata:
  name: bishop
spec:
  selector:
    app.kubernetes.io/name: bishop
  ports:
    - protocol: TCP
      port: 80
      targetPort: 9376
---
# Source: example/templates/pods.yaml
apiVersion: v1
kind: Pod
metadata:
  name: bishop-nginx
  labels:
    app.kubernetes.io/name: bishop
spec:
  containers:
  - name: nginx
    image: nginx:1.14.2
    ports:
    - containerPort: 80
---
# Source: example/templates/deployments.yaml
apiVersion: apps/v1
kind: Deployment
metadata:
  name: nginx-deployment
  labels:
    app: nginx-pawn
spec:
  replicas: 3
  selector:
    matchLabels:
      app.kubernetes.io/name: pawn
  template:
    metadata:
      labels:
        app.kubernetes.io/name: pawn
    spec:
      containers:
      - name: nginx-pawn
        image: nginx:1.14.2
        ports:
        - containerPort: 80
---
# Source: example/templates/ingresses.yaml
apiVersion: networking.k8s.io/v1
kind: Ingress
metadata:
  name: pawn-ingress
  annotations:
    nginx.ingress.kubernetes.io/rewrite-target: /
spec:
  ingressClassName: nginx-example
  rules:
  - http:
      paths:
      - path: /
        pathType: Prefix
        backend:
          service:
            name: pawn
            port:
              number: 80
---
# Source: example/templates/ingresses.yaml
apiVersion: networking.k8s.io/v1
kind: Ingress
metadata:
  name: bishop-ingress
  annotations:
    nginx.ingress.kubernetes.io/rewrite-target: /
spec:
  ingressClassName: nginx-example
  rules:
  - http:
      paths:
      - path: /
        pathType: Prefix
        backend:
          service:
            name: bishop
            port:
              number: 80
`;
function useDiagram() {
  const [diagram, setDiagram] = useState(initialDiagramState);
  const getDiagram = async (resourceDescription) => {
    const body = { resourceDescription };
    const response = await fetch("/api/v1/diagrams", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (response.status !== 201) {
      console.log("An error occurred. Abort.");
    } else {
      const data = await response.json();
      return setDiagram(data);
    }
  };
  return { diagram, getDiagram };
}

function App() {
  const { diagram, getDiagram } = useDiagram();
  const [resourceDescription, setResourceDescription] = useState("");
  return (
    <div className="c">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>k8smap</h1>
        <p>Insert your resource descriptions below.</p>
        <textarea
          value={resourceDescription}
          onChange={(event) => setResourceDescription(event.target.value)}
          className="card w-100"
          style={{ height: "40vh" }}
          placeholder={placeholder}
        />
        <button
          onClick={() => getDiagram(resourceDescription)}
          type="button"
          className="btn primary"
        >
          Create Diagram
        </button>
      </div>
      <hr />
      <div>
        <div dangerouslySetInnerHTML={{ __html: diagram.svg }}></div>
      </div>
    </div>
  );
}

export default App;
